import * as React from "react"


import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Earn from '../components/Earn'
import Alternative from '../components/Alternative'
import Modern from "../components/Moderns"
import Kearney from "../components/Kearney"
import Faqs from "../components/Faqs"

const IndexPage = () => (
  <Layout>
    <Seo title="Nursing Careers" />
    <Hero />
    <Earn />
    <Alternative />
    <Modern />
    <Kearney />
    <Faqs />
  </Layout>
)

export default IndexPage
