import * as React from "react";
import { useRef} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const KEARNEY_QUERY = graphql`
  query KEARNEY_QUERY {
    prismicRnLandingPa {
      data {
        kearney_gallery {
          kearney_image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        kearney_sub_headline {
          text
        }
        kearney_content {
          text
        }
        kearney_things {
          thing_icon {
            gatsbyImageData(placeholder: BLURRED)
          }
          thing_headline {
            text
          }
          thing_content {
            text
          }
        }
        fast_facts {
          fact_label {
            text
          }
          fact {
            text
          }
        }
      }
    }
  }
`;

const Slider = ({ images }) => {
  const splideRef = useRef(null);

  return (
    <Splide
      ref={splideRef}
      options={{
        rewind: false,
        pagination: false,
        cover: true,
        arrowPath: `M26.6833 18.3334H6.66667V21.6667H26.6833V26.6667L33.3333 20L26.6833 13.3334V18.3334Z`,
      }}
    >
      {images.map((image, index) => (
        <SplideSlide key={index}>
          <GatsbyImage
            image={getImage(image.kearney_image)}
            alt="carousel image of facility"
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};

const Kearney = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(KEARNEY_QUERY);

  return (
    <div className="kearney">
      <div className="kearney__gallery">
        <Slider images={data.kearney_gallery} />
        <div className="header">
          <h2 className="font-green">
            kea<span className="font-white">rn</span>ey
          </h2>
        </div>
      </div>
      <div className="kearney__details">
        <div className="wrap">
          <div className="top">
            <h3>{data.kearney_sub_headline.text}</h3>
            <p className="body">{data.kearney_content.text}</p>
          </div>
          <div className="thing-container">
            {data.kearney_things.map((item, index) => (
              <div className="thing" key={item.thing_headline.text}>
                <div className="icon">
                  <GatsbyImage image={getImage(item.thing_icon)} alt="icon" />
                </div>
                <div className="thing__content">
                  <h5 className="font-blue">{item.thing_headline.text}</h5>
                  <div className="thing__body font-white">
                    <p>{item.thing_content.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="fast-facts">
            <p className="fast-facts__header">fast facts</p>

            <div className="fast-facts__container">
              {data.fast_facts.map(item => (
                <div className="fast-fact" key={item.fact_label.text}>
                  <p className="label">{item.fact_label.text}</p>
                  <p className="fact">{item.fact.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kearney;
