import * as React from "react";
import { useForm } from "react-hook-form";
import { useQuizState } from "../lib/quizState";
import Confirmation from './Confirmation';


const icons = {
  person: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9ZM8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z"
        fill="#B0D898"
      />
    </svg>
  ),
  email: (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z"
        fill="#B0D898"
      />
    </svg>
  ),
  phone: (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.00999999L2 0C0.9 0 0 0.9 0 2V20C0 21.1 0.9 22 2 22H12C13.1 22 14 21.1 14 20V2C14 0.9 13.1 0.00999999 12 0.00999999ZM12 18H2V4H12V18Z"
        fill="#B0D898"
      />
    </svg>
  ),
};



const ContactForm = ({ setStep }) => {


  const [state, setState] = React.useState(0)

  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm();

  const quizState = useQuizState()


  //FOR RYAN
  const onSubmit = data => {
    var submitData = {
      contact: {
        "firstName": data.firstName,
        "lastName": data.LastName,
        "email": data.email,
        "phone": data.phone,
      },
      "follow_up": data.followUp,
      "quiz": quizState.quizState,
      "api_token": '68f077d78471ad2c1bcd0c3ee702cfd8775b9d26321c3323d732d9ea0791c4de243def1b'
    };


    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;




    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.responseText === "ok") {

          if (setStep) {
            setStep(3)
          } else {
            setState(1)
          }

        }

      }
    });

    //xhr.open("POST", "http://localhost:53296/activecampaign/addcontact");
    xhr.open("POST", "https://scooters.bozell.com/send/activecampaign/addcontact");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(submitData));



  }

  const InputWrapper = ({ name, placeholder, icon }) => (
    <>
      <div className="input-wrapper">
        {icons[icon]}
        <input {...register(name, { required: true })} placeholder={placeholder} />
      </div>
      <p className="error">{errors[name] && `${placeholder} is required`}</p>
    </>
  );

  if (!state) {
    return (
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper name="firstName" placeholder="First name" icon="person" />
        <InputWrapper name="LastName" placeholder="Last name" icon="person" />
        <InputWrapper name="email" placeholder="Email" icon="email" />
        <InputWrapper name="phone" placeholder="Phone number" icon="phone" />
        <div className="radios">
          <label>
            <input
              {...register("followUp", { required: true })}
              type="radio"
              value="Schedule a callback"
            />
            <span>Schedule a callback</span>
          </label>
          <label>
            <input
              {...register("followUp", { required: true })}
              type="radio"
              value="Email follow-up"
            />
            <span>Email follow-up</span>
          </label>
        </div>
        <input type="submit" value="LET'S TALK" />
      </form>
    );
  } else {
    return (<Confirmation />)
  }
};

export default ContactForm;