import * as React from "react";
import {useEffect, useState, useRef} from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Quote from "./Quote"

const EARN_QUERY = graphql`
  query EARN_QUERY {
    prismicRnLandingPa {
      data {
        earn_sub_headline {
          text
        }
        earn_content {
          text
        }
        earn_image {
          gatsbyImageData(placeholder: BLURRED)
        }
        earn_quote {
          name {
            text
          }
          quote {
            text
          }
        }
        benefits {
          icon {
            gatsbyImageData(placeholder: BLURRED)
          }
          benefit {
            text
          }
          benefit_info {
            html
          }
        }
      }
    }
  }
`;

const Earn = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(EARN_QUERY);
  const image = getImage(data.earn_image);

  const [containerHeight, setContainerHeight] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const benefitContainer = useRef(null)
  const benefitInner = useRef(null)
  const arrowRef = useRef(null)
  
  const expand = () => {
    if (!benefitContainer.current.classList.contains("open")) {
      benefitContainer.current.classList.add("open");
      arrowRef.current.classList.add("open");
      benefitContainer.current.style.maxHeight =
        `${benefitInner.current.scrollHeight}px`;
      setIsExpanded(true)
    } else {
      benefitContainer.current.classList.remove("open");
      arrowRef.current.classList.remove("open");
      benefitContainer.current.style.maxHeight = `${containerHeight}px`;
      setIsExpanded(false)
    }
  }

  useEffect( () => {
    const benefits = [...document.querySelectorAll('.benefit')]
    setContainerHeight(benefits[0].scrollHeight + benefits[1].scrollHeight + 250);
  },[] )

  return (
    <div className="earn">
      <div className="wrap">
        <div className="earn__head max-w">
          <h2 className="font-green">
            EA<span className="font-white">RN</span>
          </h2>
          <p className="sub-headline font-white">
            {data.earn_sub_headline.text}
          </p>
        </div>
        <div className="earn__img">
          <GatsbyImage image={image} alt="nurse image" />
          <Quote
            quote={data.earn_quote[0].quote.text}
            name={data.earn_quote[0].name.text}
          />
        </div>
        <div className="earn__copy max-w">
          <p className="body--large font-white">{data.earn_content.text}</p>
        </div>

        <div
          className="earn-benefits max-w"
          style={{ maxHeight: `${containerHeight}px` }}
          ref={benefitContainer}
        >
          <div className="earn-benefits__inner" ref={benefitInner}>
            {data.benefits.map((item, index) => (
              <div className="benefit" key={item.benefit.text}>
                <div className="icon">
                  <GatsbyImage image={getImage(item.icon)} alt="icon" />
                </div>
                <div className="benefit__content">
                  <h5 className="font-green">{item.benefit.text}</h5>
                  <div
                    className="benefit__body font-white"
                    dangerouslySetInnerHTML={{ __html: item.benefit_info.html }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="expand" onClick={expand} onKeyPress={expand} role="button" tabIndex={0}  ref={arrowRef}>
          {!isExpanded && <span className="font-green">Show all Benefits</span> }
          {isExpanded && <span className="font-green">Show less Benefits</span> }
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.33337 13.3334L16 20L22.6667 13.3334H9.33337Z"
              fill="#B0D898"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Earn;
