import * as React from "react";

const Confirmation = ({setStep}) => {
  
  return (<div>
       {setStep &&
     <div className="back" onClick={() => setStep(1)} onKeyPress={() => setStep(1)} role="button" tabIndex={0}>
     <svg
       width="8"
       height="12"
       viewBox="0 0 8 12"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
     >
       <path
         d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
         fill="#033B4A"
       />
     </svg>
     Back to Quiz
   </div>
   }
   <br /><br />
   <div className="content"><h5>Thank you!</h5><p>We have received your submission.</p></div></div>

  )
}

export default Confirmation;