import * as React from "react";

const Quote = ({quote,name}) => {
  return (
    <blockquote className="quote">
      <svg
        width="31"
        height="26"
        viewBox="0 0 31 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.296 25.72C11.376 25.72 13.576 23.344 13.576 20.616C13.576 15.952 9.88 15.16 9.088 14.72C8.384 14.368 7.768 13.312 7.768 12.168C7.768 7.85598 11.2 3.27998 12.432 2.39998C12.872 2.04798 13.312 1.51998 13.312 1.07998C13.312 0.639978 12.96 0.375977 12.432 0.375977C11.816 0.375977 10.936 0.727977 9.968 1.25598C8.648 1.95998 0.112 7.59198 0.112 16.832C0.112 22.64 4.248 25.72 8.296 25.72ZM24.928 25.72C28.008 25.72 30.208 23.344 30.208 20.616C30.208 15.952 26.512 15.16 25.72 14.72C25.016 14.368 24.4 13.312 24.4 12.168C24.4 7.85598 27.832 3.27998 29.064 2.39998C29.504 2.04798 29.944 1.51998 29.944 1.07998C29.944 0.639978 29.592 0.375977 29.064 0.375977C28.448 0.375977 27.568 0.727977 26.6 1.25598C25.28 1.95998 16.744 7.59198 16.744 16.832C16.744 22.64 20.88 25.72 24.928 25.72Z"
          fill="#B0D898"
        />
      </svg>
      <p className="the-quote">{quote}</p>
      <p className="the-name">{name}</p>
    </blockquote>
  );
}

export default Quote;
