import * as React from "react";
import { useQuizState } from "../lib/quizState";

const Slider = ({ label, description, name, value, setQuizState }) => {

  const handleChange = (e) => {
    setQuizState((prevState) => ({
      ...prevState,
      [name]: e.target.value
    }))
  }

  return (
  <div className="slider-wrapper">
    <h5>{label}</h5>
    <p>{description}</p>
    <input
      className="slider"
      step="1"
      type="range"
      name={name}
      min="0"
      max="10"
      value={value}
      onChange={(e) => handleChange(e)}
    ></input>
    <div className="important">
      <span>less important</span>
      <span>more important</span>
    </div>
  </div>
)};

const LongForm = () => {
  const {quizState,setQuizState} = useQuizState();
  console.log(quizState)
  return (
    <form className="long-form">
      <div className="slider-wrapper">
        <h5>&nbsp;</h5>
      </div>
      <Slider
        label="Pay"
        description="Regular salary analysis, 401(k), holiday pay, bonuses"
        name="pay"
        value={quizState.pay}
        setQuizState={setQuizState}
      />
      <Slider
        label="Education Assistance"
        description="Financial aid, loan forgiveness and reimbursement, nurse residency"
        name="education"
        value={quizState.education}
        setQuizState={setQuizState}
      />
      <Slider
        label="Work/Life"
        description="PTO, childcare discounts, YMCA memberships"
        name="workLife"
        value={quizState.workLife}
        setQuizState={setQuizState}
      />
      <Slider
        label="Community/Friendship"
        description="Social events, recognition, departmental outings"
        name="community"
        value={quizState.community}
        setQuizState={setQuizState}
      />
      <Slider
        label="Medical Insurance"
        description="Health insurance, dental, vision, FSA, HSA"
        name="medical"
        value={quizState.medical}
        setQuizState={setQuizState}
      />
    </form>
  );
};

export default LongForm;
