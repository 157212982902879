import * as React from "react";
import {useRef, useState} from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const MODERN_QUERY = graphql`
  query MODERN_QUERY {
    prismicRnLandingPa {
      data {
        modern_sub_headline {
          text
        }
        modern_content {
          text
        }
        modern_gallery {
          modern_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        people {
          person_image {
            gatsbyImageData(placeholder: BLURRED)
          }
          name {
            text
          }
          title {
            text
          }
        }
        tour {
          tour_title {
            text
          }
          tour_image {
            gatsbyImageData(placeholder: BLURRED)
          }
          tour_link {
            url
          }
        }
      }
    }
  }
`;

const Slider = ({ images, setSplideSlide }) => {
  const splideRef = useRef(null);

  return (
    <Splide
      ref={splideRef}
      options={{
        rewind: false,
        pagination: false,
        cover: true,
        arrowPath: `M26.6833 18.3334H6.66667V21.6667H26.6833V26.6667L33.3333 20L26.6833 13.3334V18.3334Z`,
      }}
      onMove={() => setSplideSlide(splideRef.current.splide.index)}
    >
      {images.map((image, index) => (
        <SplideSlide key={index}>
          <GatsbyImage
            image={getImage(image.modern_image)}
            alt="carousel image of facility"
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};



const Modern = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(MODERN_QUERY);
  const [splideSlide, setSplideSlide] = useState(0)

  return (
    <div className="modern">
      <div className="modern__top">
        <div className="modern__content">
          <h2>
            mode<span className="font-green">rn</span>
          </h2>
          <p className="sub-headline">{data.modern_sub_headline.text}</p>
          <p className="body--large">{data.modern_content.text}</p>
        </div>
        <div className="modern__carousel">
          <Slider
            images={data.modern_gallery}
            setSplideSlide={setSplideSlide}
          />
          <div className="descriptions">
            {data.modern_gallery.map((item, index) => (
              <span
                key={item.modern_image.alt}
                className={index === splideSlide ? `show` : ``}
              >
                {item.modern_image.alt}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="modern__bottom">
        <div className="modern__tour">
          <a href={data.tour[0].tour_link.url} _target="blank" rel="noopener">
            <p>{data.tour[0].tour_title.text}</p>
            <div className="tour-image">
              <GatsbyImage
                image={getImage(data.tour[0].tour_image)}
                alt="Take a virtual tour"
              />
            </div>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM16 12L10 16L10 8L16 12Z"
                  fill="#B0D898"
                />
              </svg>
              Click here to tour
            </span>
          </a>
        </div>
        <div className="modern__people">
          {data.people.map(person => (
            <div className="person" key={person.name.text}>
              <GatsbyImage
                image={getImage(person.person_image)}
                alt={person.name.text}
              />
              <div className="name">{person.name.text}</div>
              <div className="title">{person.title.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modern;
