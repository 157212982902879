import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Quote from "../components/Quote"
import ContactForm from "./ContactForm"

const FAQS_QUERY = graphql`
  query FAQS_QUERY {
    prismicRnLandingPa {
      data {
        faqs {
          answer {
            text
          }
          question {
            text
          }
        }
        faq_quote {
          name {
            text
          }
          quote {
            text
          }
        }
      }
    }
  }
`;

const Faqs = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(FAQS_QUERY);

  const expand = (e) => {
    const parent = e.target.parentElement;
    const body = e.target.nextSibling;

    if (!parent.classList.contains("open")) {
      parent.classList.add("open");
      body.style.maxHeight = `${body.scrollHeight}px`;
    } else {
      parent.classList.remove("open");
      body.style.maxHeight = `0px`;
    }
  }


  return (
    <div className="faqs">
      <div className="max-width">
        <div className="faqs__left">
          <h2>FAQs</h2>
          <div className="faqs__container">
            {data.faqs.map(item => (
              <div className="faq" key={item.question.text}>
                <div className="faq__header" role="button" tabIndex={0} onKeyPress={e => expand(e)} onClick={e => expand(e)}>
                  <span>{item.question.text}</span>
                  <div className="icon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        className="bottom-bar"
                        x="8"
                        width="2"
                        height="18"
                        fill="#B0D898"
                      />
                      <rect
                        x="18"
                        y="8"
                        width="2"
                        height="18"
                        transform="rotate(90 18 8)"
                        fill="#B0D898"
                      />
                    </svg>
                  </div>
                </div>
                <div className="faq__body">
                  <div className="faq__body-inner">
                    <p>{item.answer.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="faqs__right">
          <div className="faqs__right-container">
            <div className="placehold">
              <h5>More questions? We’re happy to answer anything.</h5>
              <ContactForm />
            </div>
            {data.faq_quote[0] &&
              <Quote
                quote={data.faq_quote[0].quote.text}
                name={data.faq_quote[0].name.text}
              />
            }
            {!data.faq_quote[0] &&
              <div className="faqs__spacer" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
