import * as React from "react";
import {useState} from "react"
import { useStaticQuery, graphql } from "gatsby";
import Quote from "./Quote"
import LongForm from "./LongForm"
import ContactForm from "./ContactForm"
import Confirmation from './Confirmation'

const ALTERNATIVE_QUERY = graphql`
  query ALTERNATIVE_QUERY {
    prismicRnLandingPa {
      data {
        alt_sub_headline {
          text
        }
        alt_quote {
          name {
            text
          }
          quote {
            text
          }
        }
        alt_content {
          text
        }
      }
    }
  }
`;

const Alternative = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(ALTERNATIVE_QUERY);

  const [step, setStep] = useState(1);
  
  const showStepThree = step === 3 ? `show` : `hide`;
  const showStepTwo = step === 2 ? `show` : `hide`;
  const showStepOne = step === 1 ? `show` : `hide`;
  

  return (
    <div className="alternative">
      <div className="alternative__content">
        <div className="alt-wrap">
          <h2>
            alte<span className="font-green">rn</span>ative
          </h2>
          <p className="sub-headline">{data.alt_sub_headline.text}</p>
          <p className="body--large">{data.alt_content.text}</p>
        </div>
        <div className="alternative__quote">
          <div className="speech-bubble">
            <Quote
              name={data.alt_quote[0].name.text}
              quote={data.alt_quote[0].quote.text}
            />
          </div>
        </div>
      </div>
      <div className="alternative-form">
        <div className="forms-wrapper">
          <div className={`step-one ${showStepOne}`}>
            <LongForm />
            <div className="next-step" role="button" tabIndex={0} onClick={() => setStep(2)} onKeyPress={() => setStep(2)}>
              Next Step
            </div>
          </div>
          <div className={`step-two ${showStepTwo}`}>
            <div className="back" role="button" tabIndex={0} onKeyPress={() => setStep(1)} onClick={() => setStep(1)}>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
                  fill="#033B4A"
                />
              </svg>
              Back to Quiz
            </div>
            <div className="checks">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 15.75L10.5 32.25L2.25 24.0004"
                  stroke="#B0D898"
                  stroke-width="4.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M45.7501 15.75L29.2501 32.25L26.239 29.2389"
                  stroke="#B0D898"
                  stroke-width="4.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="content">
              <h5>Great! We have your list of most important benefits.</h5>
              <p className="body--large">
                The next step is to fill out the form below to send these to us
                so that we can begin our conversation.
              </p>
            </div>
            <ContactForm setStep={setStep} />
          </div>

          <div className={`step-three ${showStepThree}`}><Confirmation setStep={setStep} /></div>
          
        </div>
      </div>
    </div>
  );
};

export default Alternative;
