import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactForm from "./ContactForm"

const HERO_QUERY = graphql`
  query HERO_QUERY {
    prismicRnLandingPa {
      data {
        hero_overline {
          text
        }
        hero_headline {
          text
        }
        hero_content {
          text
        }
        hero_image {
          gatsbyImageData(placeholder: BLURRED)
        }
        hero_bottom {
          hero_point {
            html
          }
        }
      }
    }
  }
`;

const Hero = () => {
  const {
    prismicRnLandingPa: { data },
  } = useStaticQuery(HERO_QUERY);
  const image = getImage(data.hero_image);

  return (
    <div className="hero">
      <div className="hero__wrapper">
        <div className="hero__img">
          <GatsbyImage image={image} alt="KRMC Logo" />
        </div>
        <div className="hero__content">
          <div className="wrap">
            <p className="overline">{data.hero_overline.text}</p>
            <h1>{data.hero_headline.text}</h1>
            <p className="body--large">{data.hero_content.text}</p>
          </div>
        </div>
      </div>
      <div className="hero-bottom">
        <div className="hero-bottom__wrapper">
          {data.hero_bottom.map((item,index) => (
            <div className="hero-point" key={index} dangerouslySetInnerHTML={{__html: item.hero_point.html}}></div>
          ))} 
        </div> 

        <div className="hero-form">
          <h3>Take the next step in changing your career.</h3>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Hero;
